<div *ngIf="!editable" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">View {{ Itype }}</h6>
</div>
<div *ngIf="editable" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">{{ headerName }}</h6>
</div>
<div class="row invoice-body">
  <div
    *ngIf="isLCMTab && editable && !isLCMCompleted"
    class="viewPdf d-flex"
    style="right: 180px"
  >
    <div>
      <button class="btnUpload" (click)="downloadLCMTemplate()">
        Download Template
      </button>
    </div>
    <div style="margin-top: 2px" class="ml-2">
      <input
        type="file"
        id="custom-upload"
        [(ngModel)]="uploadExcelValue"
        (change)="onChange($event)"
        hidden
        #inputFile
        accept=".xls,.xlsx"
      />
      <label for="custom-upload" class="btnUpload filter_btn_m f-12 mr-2"
        ><span style="position: relative; top: 5px">Upload Excel</span></label
      >
    </div>
  </div>
  <div
    class="viewPdf"
    style="right: 160px"
    *ngIf="
      (editPermissionBoolean ||
        changeApproveBoolean ||
        financeApproveBoolean) &&
      (submitBtn_boolean || vendorUplaodBoolean) &&
      lineTabBoolean &&
      !isLinenonEditable
    "
  >
    <!-- <button
      class="btnUpload bg-blue mr-2"
      (click)="open_dialog_comp('Amend')"
    >
      PO Amend request
    </button> -->
    
    <button *ngIf="subStstusId == 34" class="btnUpload bg-blue mr-2" (click)="open_dialog_comp('flip')">
      Flip PO
    </button>
    <button
      class="btnUpload bg-blue"
      (click)="captureComments('AddLine', null)"
    >
      <i class="fa fa-plus" aria-hidden="true"></i> Add Line
    </button>
  </div>
  <!-- <div
    class="viewPdf"
    style="right: 160px"
    *ngIf="multiPOBool && lineTabBoolean"
  ><button class="btnUpload bg-blue mr-2" (click)="mutliPOEdit('exception')">
    Edit MultiPO
  </button>
</div> -->
  <div class="viewPdf" *ngIf="!isPdfAvailable" style="right: 100px">
    <button class="btnUpload" (click)="DownloadPDF()">
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
  </div>
  <div class="viewPdf" *ngIf="!isPdfAvailable">
    <button class="btnUpload" (click)="viewPdf()">{{ btnText }}</button>
  </div>
  <div
    class="col-12 p-0 pl-1"
    [ngClass]="!isPdfAvailable && showPdf ? 'col-lg-5' : 'col-lg-12'"
  >
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
        *ngIf="!isServiceData"
        class="nav-item mr-2"
        pTooltip="Vendor details"
        tooltipPosition="top"
        (click)="changeTab('show', 'vendor')"
      >
        <a
          class="nav-link"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          [ngClass]=" tabName == 'vendor'? 'active' : ''"
          >Vendor</a
        >
      </li>
      <li
        *ngIf="isServiceData"
        class="nav-item mr-2"
        pTooltip="Service details"
        tooltipPosition="top"
        (click)="changeTab('show', 'service')"
      >
        <a
          class="nav-link"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          [ngClass]=" tabName == 'service'? 'active' : ''"
          >Service</a
        >
      </li>
      <li
        class="nav-item mr-2"
        pTooltip="Header details"
        tooltipPosition="top"
        (click)="changeTab('show', 'header')"
      >
        <a
          class="nav-link"
          id="header-tab"
          data-toggle="tab"
          href="#header"
          role="tab"
          aria-controls="header"
          aria-selected="false"
          [ngClass]=" tabName == 'header'? 'active' : ''"
          >Header</a
        >
      </li>
      <li
        class="nav-item"
        pTooltip="Line details"
        tooltipPosition="top"
        (click)="changeTab('hide', 'line')"
        *ngIf="!isLCMInvoice"
      >
        <a
          class="nav-link"
          id="line-tab"
          data-toggle="tab"
          href="#line"
          role="tab"
          aria-controls="line"
          [ngClass]="tabName == 'line'? 'active' : ''"
          aria-selected="false"
          >Line details</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="PO Lines details"
        
        tooltipPosition="top"
        (click)="changeTab('hide', 'poline')"
        *ngIf="!isLCMInvoice && Itype == 'Invoice' && !(subStstusId == 7 || subStstusId == 29) && !multiPOBool && POlineBool"
      >
        <a
          class="nav-link"
          id="POline-tab"
          data-toggle="tab"
          href="#POline"
          role="tab"
          aria-controls="POline"
          aria-selected="false"
          [ngClass]="tabName == 'poline'? 'active' : ''"
          >PO Lines</a
        >
      </li>

      <li
        class="nav-item"
        pTooltip="LCM details"
        tooltipPosition="top"
        (click)="changeTab('hide', 'LCM')"
        *ngIf="isLCMInvoice"
      >
        <a
          class="nav-link"
          id="lcm-tab"
          data-toggle="tab"
          href="#lcm"
          role="tab"
          aria-controls="lcm"
          [ngClass]="isLCMInvoice && tabName == 'LCM' ? 'active' : ''"
          aria-selected="false"
          >LCM line details</a
        >
      </li>


      <li
      class="nav-item ml-2"
      pTooltip="GRN details"
      tooltipPosition="top"
      *ngIf="Itype == 'Invoice'  && !isServiceData"
      (click)="changeTab('hide','grn')"
    >
      <a
        class="nav-link"
        id="grn-tab"
        data-toggle="tab"
        href="#grn"
        role="tab"
        aria-controls="grn"
        aria-selected="false"
        [ngClass]="tabName == 'grn'?'active':''"
        >GRN Data</a
      >
    </li>


      <li
        *ngIf="
          !isServiceData && !approval_selection_boolean && Itype == 'Invoice'
        "
        class="nav-item ml-2"
        pTooltip="Support Doc"
        tooltipPosition="top"
        (click)="changeTab('show', 'support')"
      >
        <a
          [ngClass]="tabName == 'support' ? 'bg-2 active' : 'bg-1'"
          class="nav-link"
          id="support-tab"
          data-toggle="tab"
          href="#support"
          role="tab"
          aria-controls="support"
          aria-selected="false"
          >Support Doc</a
        >
      </li>

      <li
        *ngIf="!isServiceData && approval_selection_boolean && isLCMCompleted"
        class="nav-item ml-2"
        pTooltip="Approver Selection"
        tooltipPosition="top"
        (click)="changeTab('hide', 'approver_selection')"
      >
        <a
          class="nav-link"
          id="approver_selection-tab"
          data-toggle="tab"
          href="#approver_selection"
          role="tab"
          aria-controls="approver_selection"
          aria-selected="false"
          [ngClass]="
            approval_selection_boolean && !isLCMInvoice && tabName =='approver_selection' ? 'active' : ''
          "
          >Approver Selection</a
        >
      </li>
      <!-- <button class="raiseAlert" pTooltip="raise an alert" tooltipPosition="top"><i class="fa fa-exclamation-triangle"
          aria-hidden="true"></i></button> -->
    </ul>
    <div class="bg-design-all tabsDiv">
      <h6 class="upload-time" *ngIf="uploadtime">
        Upload Completed in - {{ uploadtime }}
      </h6>
      <div class="tabDiv tab-content" id="myTabContent">
        <!-- vendor -->
        <div
          class="tab-pane fade"
          [ngClass]=" tabName == 'vendor' || tabName == 'service'? 'show active' : ''"
          id="vendor"
          role="tabpanel"
          aria-labelledby="vendor-tab"
        >
          <div class="displayFlex">
            <div *ngFor="let data of vendorData | keyvalue; let i = index">
              <div>
                <label
                  class="label-head"
                  style="margin-bottom: -5px"
                  [title]="data.key"
                  >{{ data.key }}
                </label>
                <input
                  #inputv
                  type="text"
                  [value]="data.value"
                  [title]="data.value"
                  [disabled]="!vendorDetalilsEditBoolean"
                  class="form-control mb-1 inputHeight"
                  (blur)="method()"
                />
                <!-- <select *ngIf="data.key == 'VendorName'" name="vendor" id="vd" [(ngModel)]="data.value" class="form-control mb-1 inputHeight">
                  <option [value]="vendor.Vendor.VendorName" *ngFor="let vendor of readvendorsData">{{vendor.Vendor.VendorName}}</option>
                </select> -->
                <!-- (click)="drawrectangleonHighlight(i)"
                          [ngStyle]="{'border': (value.isError === 1 ) ? '2px solid red' : (value.isError === 0 && value.IsUpdated === 0  ) ? '1px solid lightgray': (value.IsUpdated === 1 ) ? '2px solid #37dcc7':'' } "
                        (change)="onChangeValue(value.TagLabel,inputv.value,value)"-->
              </div>
            </div>
          </div>
        </div>

        <!-- header -->
        <div
          class="tab-pane fade"
          [ngClass]=" tabName == 'header'? 'show active' : ''"
          id="header"
          role="tabpanel"
          aria-labelledby="header-tab"
        >
          <div class="displayFlex">
            <div *ngFor="let value of inputData">
              <div style="position: relative">
                <label class="label-head" style="margin-bottom: -5px"
                  >{{ value.TagLabel }}
                  <span
                    *ngIf="value.isError >= 1"
                    [pTooltip]="value.ErrorDesc"
                    tooltipPosition="top"
                    ><i
                      class="fa fa-info-circle info_icon"
                      aria-hidden="true"
                    ></i
                  ></span>
                </label>
                <input
                  *ngIf="value.TagLabel == 'InvoiceDate'"
                  #inputv
                  type="text"
                  placeholder="dd-mm-yyyy"
                  title="dd-mm-yyyy"
                  [value]="value.Value | date : 'dd-MM-yyyy'"
                  [disabled]="!editable || fin_boolean"
                  [ngStyle]="{
                    border:
                      value.isError >= 1
                        ? '2px solid red'
                        : value.isError === 0 && value.IsUpdated === 0
                        ? '1px solid lightgray'
                        : value.IsUpdated === 1
                        ? '2px solid #37dcc7'
                        : ''
                  }"
                  (change)="onChangeValue(value.TagLabel, inputv.value, value)"
                  (blur)="saveChanges()"
                  class="form-control mb-1 inputHeight"
                />
                <input
                  #inputv
                  *ngIf="value.TagLabel != 'InvoiceDate'"
                  type="text"
                  [value]="value.Value"
                  [title]="value.Value"
                  [disabled]="!editable || fin_boolean"
                  [ngStyle]="{
                    border:
                      value.isError >= 1
                        ? '2px solid red'
                        : value.isError === 0 && value.IsUpdated === 0
                        ? '1px solid lightgray'
                        : value.IsUpdated === 1
                        ? '2px solid #37dcc7'
                        : ''
                  }"
                  (change)="onChangeValue(value.TagLabel, inputv.value, value)"
                  (blur)="saveChanges()"
                  class="form-control mb-1 inputHeight"
                />
                <span
                  *ngIf="
                    value.DocumentUpdates && value.DocumentUpdates.OldValue
                  "
                >
                  <span class="old_value"
                    >Prev:
                    <span style="color: #f38a6b"
                      >"{{ value.DocumentUpdates.OldValue }}"</span
                    ></span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- line -->
        <div
          class="tab-pane fade"
          id="line"
          [ngClass]=" tabName == 'line'? 'show active' : ''"
          role="tabpanel"
          aria-labelledby="line-tab"
        >
            <button
            *ngIf="multiPOBool && lineTabBoolean" class="btnUpload bg-blue edt_mul_btn mr-2" (click)="mutliPOEdit('exception')">
              Edit MultiPO
            </button>
            <table id="linesdata" class="invert" style="width: 100%">
              <tr
                class="invert_tr"
                *ngFor="let item of lineDisplayData; let i = index"
              >
                <th
                  class="lineName invert_th_td"
                  [ngStyle]="{
                    'min-width': item.TagName == 'S.No' ? '40px' : '130px'
                  }"
                >
                  {{ item.TagName }}
                </th>
                <!-- <div>
                      <div class="linevalue invert_thtd">{{i+1}}</div>
                    </div> -->
                <td
                  class="linevalue invert_th_td"
                  *ngFor="let line1 of item.linedata; let i = index"
                >
                  <div *ngIf="item.TagName == 'S.No'">
                    <div class="linevalue invert_thtd p-2">{{ i + 1 }}</div>
                  </div>
                  <div
                    *ngFor="let line of line1 | keyvalue"
                    class="d-flex"
                    style="position: relative"
                  >
                    <div
                      *ngIf="
                        line.key == 'DocumentLineItems' && item.TagName != 'S.No'
                      "
                    >
                      <!-- {{line.value.Value |json}} -->
                      <div *ngIf="item.TagName == 'S.No'">
                        <div class="linevalue invert_thtd p-2">
                          {{ line.value.itemCode }}
                        </div>
                      </div>
                      <div *ngIf="item.TagName == 'Actions' && editable">
                        <div class="linevalue invert_thtd">
                          <button
                            style="border-color: transparent"
                            (click)="captureComments('delete', line.value)"
                          >
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <span
                        *ngIf="
                          item.TagName != 'S.No' && item.TagName != 'Actions'
                        "
                      >
                        <input
                          #inputv
                          type="text"
                          [ngClass]="
                            item.TagName == 'Description' ||
                            item.TagName == 'Name' || item.TagName == 'POLineDescription' ||item.TagName == 'GRNLineDescription'
                              ? 'w-des'
                              : 'w-sm'
                          "
                          [value] ="line.value.Value"
                          [title]="line.value.Value"
                          [name]="item.TagName"
                          [readonly]="
                            !editable || fin_boolean || isLinenonEditable
                          "
                          [ngStyle]="{
                            border:
                              line.value.isError >= 1
                                ? '2px solid red'
                                : line.value.isError === 0 &&
                                  line.value.IsUpdated === 0
                                ? '1px solid lightgray'
                                : line.value.IsUpdated === 1
                                ? '2px solid #37dcc7'
                                : ''
                          }"
                          (change)="onChangeLineValue(inputv.value, line.value)"
                          (blur)="saveChanges()"
                          class="form-control mb-1 inputHeight"
                        />
                      </span>
                    </div>
                    <div
                      *ngIf="
                        line.value?.isError >= 1 &&
                        item.TagName != 'S.No' &&
                        item.TagName != 'Actions'
                      "
                      [pTooltip]="line.value?.ErrorDesc"
                      tooltipPosition="top"
                    >
                      <i
                        class="fa fa-info-circle info_icon"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      *ngIf="
                        line.key == 'DocumentUpdates' &&
                        line.value &&
                        item.TagName != 'S.No' &&
                        item.TagName != 'Actions'
                      "
                    >
                      <span class="old_value"
                        >Prev:
                        <span style="color: #f38a6b"
                          >"{{ line.value.OldValue }}"</span
                        ></span
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </table>
        </div>

        <!--PO lines-->
        <div
          class="tab-pane fade"
          id="POline"
          role="tabpanel"
          [ngClass]=" tabName == 'poline'? 'show active' : ''"
          aria-labelledby="POline-tab"
        >
        <div class="refresh_btn"><button class="btnUpload" (click)="refreshPO()">Refresh PO</button></div>
          <table class="invert" style="width: 100%" *ngIf="POlineBool">
            <tr
              class="invert_tr"
              *ngFor="let item of polineTableData; let i = index"
            >
              <td class="lineName invert_th_td">
                {{ item.TagName }}
              </td>
              <!-- <div>
                    <div class="linevalue invert_thtd">{{i+1}}</div>
                  </div> -->
              <td
                class="linevalue invert_th_td"
                *ngFor="let line1 of poLineData;let i = index"
              >
                <span>
                  <div
                    *ngFor="let line of line1 | keyvalue"
                    class="d-flex"
                    style="position: relative"
                  >
                    <span *ngIf="line.key == item.TagName">
                      <input
                        type="text"
                        [ngClass]="line.key == 'Name' ? 'w-des' : 'w-sm'"
                        [value]="line.value"
                        [title]="line.value"
                        disabled
                        class="form-control mb-1 inputHeight"
                      />
                    </span>
                  </div>
                </span>
              </td>
            </tr>
          </table>
          <div *ngIf="!POlineBool" class="f-13 text-center">
            No PO lines are available
          </div>
        </div>
        
        <!-- GRN Data -->
        <div
          class="tab-pane fade show"
          [ngClass]="tabName == 'grn'?'show active':''"
          id="grn"
          role="tabpanel"
          aria-labelledby="grn-tab"
        >
          <div *ngIf="grnTabDatalength>0">
          <mat-accordion>
            <div class="row"  *ngFor="let grnitem of GRNTabData | keyvalue; let i = index;">
              <div class="col-sm-12">
                <mat-expansion-panel 
                [expanded]="true"
                 (opened)="setOpened(i)"
                 (closed)="setClosed(i)" style="margin-top: 10px;">
                 
                 <mat-expansion-panel-header class="KPIName">
                   <mat-panel-title class=" secondheadertoggle">
                     
                     <h6 class="kpi-header f-13 faPulsandMinus head-pos">
                       {{ grnitem.key }}
                     </h6>
                    
                   </mat-panel-title>
                 </mat-expansion-panel-header>
                 <ng-container>
                  <table class="invert" style="width: 100%">
                    <tr
                      class="invert_tr bg-wt"
                      *ngFor="let item of grnitem.value; let i = index"
                    >
                      <td
                        class="lineName invert_th_td"
                        [ngStyle]="{
                          'min-width': item.TagName == 'S.No' ? '40px' : '130px'
                        }"
                      >
                        {{ item.TagName }}
                      </td>
                      <!-- <div>
                            <div class="linevalue invert_thtd">{{i+1}}</div>
                          </div> -->
                      <td
                        class="linevalue invert_th_td"
                        *ngFor="let line1 of item.linedata; let i = index"
                      >
                        <div
                          *ngFor="let line of line1 | keyvalue"
                          class="d-flex"
                          style="position: relative"
                        >
                          <div *ngIf="line.key == 'DocumentLineItems'">
                            <div *ngIf="item.TagName == 'S.No'">
                              <div class="linevalue invert_thtd p-2">
                                {{ line.value.itemCode }}
                              </div>
                            </div>
                            <span
                              *ngIf="
                                item.TagName != 'S.No' && item.TagName != 'Actions'
                              "
                            >
                              <input
                                #inputv
                                type="text"
                                [ngClass]="
                                  item.TagName == 'Description' ||
                                  item.TagName == 'Name'
                                    ? 'w-des'
                                    : 'w-sm'
                                "
                                [value]="line.value.Value"
                                [title]="line.value.Value"
                                readonly
                                class="form-control mb-1 inputHeight"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                 </ng-container>
           
               </mat-expansion-panel>
              </div>
            </div>
           
            
          </mat-accordion>
          </div>
          <div *ngIf="!(grnTabDatalength>0)" class="f-13 text-center">
            No GRN data available
          </div>
        </div>



        <!--LCM-->
        <div
          class="tab-pane fade"
          [ngClass]="isLCMInvoice && tabName=='LCM' ? 'show active' : ''"
          id="lcm"
          role="tabpanel"
          aria-labelledby="lcm-tab"
        >
          <form
            *ngIf="editable && !isLCMCompleted"
            class="d-flex flex-wrap"
            style="gap: 0px 5px"
            #LCMLineForm="ngForm"
            (ngSubmit)="AddLCMLine(LCMLineForm.value)"
          >
            <div>
              <label class="f-12 mb-0">Select Entity</label><br />
              <!-- <p-autoComplete placeholder="" (onSelect)="onSelectEnt($event)" [group]="false"
                [suggestions]="filteredEnt" (completeMethod)="filterEnttity($event)" field="EntityName"
                [dropdown]="true" [style]="{ width: '160px' }" name="EntityName" [(ngModel)]="EntityName" required>
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span class="f-12">{{ group.EntityName }}</span>
                  </div>
                </ng-template>
              </p-autoComplete> -->
              <select
                class="form-control inputHeight"
                style="width: 160px"
                name="EntityName"
                [(ngModel)]="EntityName"
                [title]="EntityName"
                (change)="onSelectEntity(EntityName)"
              >
                <option
                  *ngFor="let entity of entityList"
                  [value]="entity.EntityName"
                >
                  {{ entity.EntityName }}
                </option>
              </select>
            </div>
            <div>
              <label class="f-12 mb-0">Select PO Number</label><br />
              <p-autoComplete
                placeholder=" "
                (onSelect)="selectedPO($event)"
                [group]="false"
                [suggestions]="filteredPO"
                (completeMethod)="filterPOnumber($event)"
                field="PODocumentID"
                [dropdown]="true"
                [style]="{ width: '160px' }"
                name="PoDocumentId"
                ngModel
                required
              >
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span class="f-12">{{ group.PODocumentID }}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>

            <div>
              <label class="f-12 mb-0">Line Description</label><br />
              <p-autoComplete
                placeholder=" "
                (onSelect)="OnSelectLine($event)"
                [group]="false"
                [suggestions]="filteredLCMLines"
                (completeMethod)="filterLCMLine($event)"
                field="PoLineDescription"
                [dropdown]="true"
                [style]="{ width: '160px' }"
                name="PoLineDescription"
                ngModel
                required
              >
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span class="f-12">{{ group.PoLineDescription }}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>

            <!-- <div>
              <label class="f-12 mb-0">Item ID</label><br />
              <p-autoComplete placeholder="" (onSelect)="OnSelectLine($event)" [group]="false"
                [suggestions]="filteredLCMLines" (completeMethod)="filterLCMLine($event)" field="ItemNumber"
                [dropdown]="true" name="ItemNumber" [style]="{ width: '160px' }" ngModel required>
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span class="f-12">{{ group.Name }}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div> -->

            <!-- <div>
              <label class="f-12 mb-0">Voyage Number</label><br />
              <p-autoComplete placeholder="" (onSelect)="onSelectVoyage($event)" [group]="false"
                [suggestions]="filteredVoyage" (completeMethod)="filterVoyage($event)" field="voyage_number"
                [dropdown]="true" name="VoyageNumber" [style]="{ width: '160px' }" ngModel required>
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span class="f-12">{{ group.Name }}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div> -->
            <div>
              <label class="f-12 mb-0">Charges code</label><br />
              <p-autoComplete
                placeholder=""
                (onSelect)="onSelectCost($event)"
                [group]="false"
                [suggestions]="filteredCost"
                (completeMethod)="filterCost($event)"
                field="MarkupCode"
                [dropdown]="true"
                [style]="{ width: '160px' }"
                name="CostCategory"
                ngModel
                required
              >
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span class="f-12">{{ group.MarkupCode }}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>

            <!-- <div>
              <label class="f-12 mb-0"> Item ID</label><br />
              <input
                type="text"
                class="form-control inputHeight"
                style="width: 120px"
                name="ItemID"
                [(ngModel)]="itemId"
                ngModel
                disabled
              />
            </div> -->
            <div>
              <label class="f-12 mb-0"> Voyage Number</label><br />
              <input
                type="text"
                class="form-control inputHeight"
                style="width: 120px"
                name="VoyageNumber"
                [(ngModel)]="selectedVoyage"
                ngModel
                disabled
              />
            </div>
            <div>
              <label class="f-12 mb-0">Vessel Number</label><br />
              <input
                type="text"
                class="form-control inputHeight"
                style="width: 120px"
                name="AGIVesselNumber"
                [(ngModel)]="AGIVesselNumber"
                disabled
              />
            </div>
            <div>
              <label class="f-12 mb-0"> Estimated value</label><br />
              <input
                type="text"
                class="form-control inputHeight"
                style="width: 120px"
                name="EstimatedValue"
                [(ngModel)]="est_val"
                ngModel
                disabled
              />
            </div>

            <div>
              <label class="f-12 mb-0">Actualized value</label><br />
              <input
                type="text"
                class="form-control inputHeight"
                style="width: 120px"
                name="ActualizedValue"
                [(ngModel)]="act_val"
                disabled
              />
            </div>

            <div>
              <label class="f-12 mb-0">Allocate</label><br />
              <input
                type="number"
                min="0"
                [max]="max_allocation"
                class="form-control inputHeight"
                style="width: 120px"
                name="Allocate"
                ngModel
                required
              />
            </div>

            <div>
              <label class="f-12 mb-0"></label><br />
              <button
                class="btnUpload bg-blue"
                [disabled]="LCMLineForm.invalid"
              >
                + Add
              </button>
            </div>
          </form>
          <table class="d-flex lcm_table_ht" *ngIf="LCMDataTable?.length > 0">
            <div *ngFor="let item of LCMTable; let i = index">
              <tr>
                <th class="lineName text-center f-12" style="min-width: 100px">
                  {{ item.name }}
                </th>
                <th
                  *ngIf="i > 7"
                  class="lineName text-center f-12"
                  style="min-width: 80px"
                >
                  Actions
                </th>
              </tr>
              <tr *ngFor="let p of LCMDataTable; let ind = index" class="mb-2">
                <td
                  class="f-12 linevalue lcm_table_td text-center"
                  [title]="p[item.field]"
                >
                  {{ p[item.field] }}
                </td>
                <td
                  *ngIf="i > 7"
                  class="f-12 linevalue text-center"
                  style="cursor: pointer"
                  (click)="captureComments('delete', p)"
                >
                  <i class="fa fa-trash-o"></i>
                </td>
              </tr>
            </div>
          </table>
          <hr />
          <div
            *ngIf="LCMDataTable?.length > 0"
            class="f-12 d-flex flex-column align-items-end"
            style="margin-right: 140px"
          >
            <div>Total Invoice Amount = {{ invoiceTotal }}</div>
            <div>Total Allocation = {{ allocateTotal }}</div>
            <div>
              Balance Invoice Amount =
              <span
                [ngStyle]="{ color: balanceAmount < 0 ? 'red' : 'green' }"
                >{{ balanceAmount }}</span
              >
            </div>
          </div>
        </div>

        <!-- Support Doc -->
        <div
          class="tab-pane fade"
          id="support"
          role="tabpanel"
          [ngClass]=" tabName == 'support'? 'show active' : ''"
          aria-labelledby="support-tab"
          style="position: relative"
        >
          <div
            *ngIf="
              supportTabBoolean &&
              support_doc_list?.length > 0 &&
              !approval_selection_boolean
            "
          >
            <table class="table">
              <tr class="f-12 lineName">
                <th>S.No</th>
                <th>Name</th>
                <th></th>
              </tr>
              <tr
                *ngFor="let file of support_doc_list; let i = index"
                class="f-11"
              >
                <td>{{ i + 1 }}</td>
                <td class="break_word">{{ file }}</td>
                <td>
                  <i
                    class="fa fa-download"
                    aria-hidden="true"
                    style="cursor: pointer"
                    (click)="downloadDoc(file)"
                  ></i>
                </td>
              </tr>
            </table>
          </div>
          <div
            *ngIf="
              supportTabBoolean &&
              !(support_doc_list?.length > 0) &&
              !approval_selection_boolean
            "
          >
            <div class="no_doc_div f-14">
              No Supporting Document available, if you have any please upload.
            </div>
          </div>
          <div class="file_table" *ngIf="uploadFileList.length > 0">
            <table class="table">
              <tr class="f-12">
                <th>S.No</th>
                <th>Name</th>
                <th></th>
              </tr>
              <tr
                *ngFor="let file of uploadFileList; let i = index"
                class="f-11"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ file.name }}</td>
                <td>
                  <i
                    class="fa fa-trash-o"
                    aria-hidden="true"
                    style="cursor: pointer"
                    (click)="removeUploadQueue(i)"
                  ></i>
                </td>
              </tr>
            </table>
          </div>
          <div
            *ngIf="supportTabBoolean && editable && !approval_selection_boolean"
          >
            <label
              for="img"
              class="img-browse"
              [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
            >
              Attach Document
            </label>
            <input
              (change)="onSelectFile($event)"
              #fileInput
              type="file"
              id="img"
              name="img"
              accept=".pdf,.doc, .docx"
              multiple
              [disabled]="uploadFileList.length > 0"
              hidden
            />
            <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

            <button
              class="img-browse h-35 b-0 ml-2"
              (click)="uploadSupport()"
              [disabled]="!(uploadFileList.length > 0)"
              [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
            >
              Upload
            </button>
          </div>
        </div>

        <!--Approval Selection-->
        <div
          class="tab-pane fade"
          [ngClass]="
            approval_selection_boolean && !isLCMInvoice && tabName =='approver_selection' ? 'show active' : ''
          "
          id="approver_selection"
          role="tabpanel"
          aria-labelledby="approver_selection-tab"
          style="position: relative"
        >
          <div *ngIf="selectionTabBoolean" class="selection_Div">
            <div class="d-flex justify-content-between flex-wrap f-12">
              <div>
                <label>Select Entity</label>
                <select
                  class="form-control inputHeight"
                  [(ngModel)]="entityName"
                  [title]="entityName"
                  disabled
                >
                  <option
                    *ngFor="let entity of entityList"
                    [value]="entity.EntityName"
                  >
                    {{ entity.EntityName }}
                  </option>
                </select>
              </div>
              <div>
                <label>Select Department</label>
                <select
                  class="form-control inputHeight"
                  [(ngModel)]="selectedDepartment"
                  (change)="onSelectDepartment($event.target.value)"
                >
                  <option
                    *ngFor="let name of DepartmentList"
                    [value]="name.DepartmentName"
                  >
                    {{ name.DepartmentName }}
                  </option>
                </select>
              </div>
              <!-- <div>
                <label>Select Category</label>
                <select
                  class="form-control inputHeight"
                  (change)="onSelectCategory($event.target.value)"
                >
                  <option value=""></option>
                </select>
              </div> -->
              <div *ngFor="let item of approverList | keyvalue; let i = index">
                <label>Select Approver {{ i + 1 }}</label>
                <!-- <div>{{item.value | json}}</div> -->
                <select
                  class="form-control inputHeight"
                  [disabled]="preApproveBoolean"
                  (change)="onSelectApprovers($event.target.value, i)"
                >
                  <option
                    *ngFor="let element of item.value"
                    [value]="element.User.idUser"
                  >
                    {{ element.User.firstName }} {{ element.User.lastName }}
                  </option>
                </select>
              </div>
              <!-- <div>
                <label>Select Approver 2</label>
                <select class="form-control inputHeight" [disabled]="preApproveBoolean">
                  <option value="">Karan</option>
                </select>
              </div> -->
            </div>

            <div class="mt-2">
              <label for="pre_approve" class="f-12">
                <input
                  type="checkbox"
                  id="pre_approve"
                  class="mr-2"
                  [(ngModel)]="preApproveBoolean"
                  (change)="onSelectPerApprove(preApproveBoolean)"
                />
                <span class="label_adjust">Pre Approved</span></label
              >
            </div>

            <div>
              <label
                for="img"
                class="img-browse bg-blue"
                [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
              >
                Attach support Document
              </label>
              <input
                (change)="onSelectFileApprove($event)"
                #fileInput
                type="file"
                id="img"
                name="img"
                multiple
                [disabled]="uploadFileList.length > 0"
                hidden
              />
              <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

              <!-- <button
                class="img-browse h-35 b-0 ml-2 bg-blue"
                (click)="uploadSupport()"
                [disabled]="!(uploadFileList.length > 0)"
                [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
              >
                Upload
              </button> -->
              <div class="f-11" style="color: #e56767">
                Note: Please upload supporting documents if you have any
              </div>
            </div>
          </div>
          <div
            *ngIf="
              tabName == 'approver_selection' && support_doc_list?.length > 0
            "
            class="selection_Div"
            style="min-height: auto"
          >
            <h6 class="f-13">Supporting Documents</h6>
            <table class="table">
              <tr class="f-12 lineName">
                <th>S.No</th>
                <th>Name</th>
                <th></th>
              </tr>
              <tr
                *ngFor="let file of support_doc_list; let i = index"
                class="f-11"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ file }}</td>
                <td>
                  <i
                    class="fa fa-download"
                    aria-hidden="true"
                    style="cursor: pointer"
                    (click)="downloadDoc(file)"
                  ></i>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div
        *ngIf="(editable || vendorUplaodBoolean) && !isLCMCompleted"
        class="btnFooter"
        mat-dialog-actions
      >
        <!-- <button mat-button class="btn btnVender mr-4" type="button">Change attachment</button> -->
        <button
          class="btn btnVender bg-btn-cancel mr-3"
          type="button"
          (click)="backToInvoice()"
        >
          Cancel
        </button>
        <button
          *ngIf="
            (editPermissionBoolean ||
              changeApproveBoolean ||
              financeApproveBoolean) &&
            (submitBtn_boolean || vendorUplaodBoolean)
          "
          class="btn btnVender bg-btn-cancel mr-3"
          (click)="captureComments('reject', null)"
        >
          Reject
        </button>
        <!-- <button
          *ngIf="isLCMInvoice"
          [disabled]="LCMDataTable?.length < 1"
          class="btn btnVender bg-btn-success mr-2"
          (click)="SaveLCM()"
        >
          Save
        </button> -->
        <button
          *ngIf="
            (editPermissionBoolean ||
              changeApproveBoolean ||
              financeApproveBoolean) &&
            (submitBtn_boolean || vendorUplaodBoolean)
          "
          class="btn btnVender bg-btn-success"
          (click)="submitChanges()"
        >
          Submit
        </button>

        <button
          *ngIf="
            (changeApproveBoolean || financeApproveBoolean) &&
            !fin_boolean &&
            approveBtn_boolean &&
            !submitBtn_boolean
          "
          class="btn btnVender bg-btn-success"
          (click)="approveChanges()"
        >
          Approve
        </button>
        <button
          *ngIf="financeApproveBoolean && fin_boolean"
          class="btn btnVender bg-btn-success"
          style="min-width: fit-content"
          (click)="financeApprove()"
        >
          Approve
        </button>
        <!-- <button *ngIf=" flipBoolean" class="btn btnVender mr-2 " (click)="Create()" >Create</button>
          <button *ngIf="!tabChangeLINEBoolean && flipBoolean"  class="btn btnVender mr-2" (click)="displayAddLabelDialog = true" pTooltip="Add Label" tooltipPosition="top" >Add Label</button>
          <button *ngIf="tabChangeLINEBoolean && flipBoolean" class="btn btnVender " (click)="addLine()" >Add Line</button> -->
      </div>

      <div
        *ngIf="approval_selection_boolean && isLCMCompleted"
        class="btnFooter"
      >
        <button
          class="btn btnVender bg-btn-cancel mr-3"
          type="button"
          (click)="backToInvoice()"
        >
          Cancel
        </button>
        <button
          class="btn btnVender bg-btn-success"
          (click)="onSubmitApprovers()"
        >
          Submit
        </button>
      </div>
    </div>
    <ngx-spinner
      bdColor="rgba(251, 251, 251, 0.8)"
      size="medium"
      color="#070900"
      [fullScreen]="false"
      type="ball-spin-clockwise"
    >
      <p style="color: rgb(0, 0, 0)"></p>
    </ngx-spinner>
  </div>

  <div
    *ngIf="!isPdfAvailable && showPdf"
    class="col-lg-7 col-12 maxPage ShowInvoiceTypeSize"
  >
    <div>
      <section>
        <div *ngIf="isImgBoolean" class="canvasConatiner_height">
          <!-- *ngFor="let img of imgArray; let i = index " -->
          <div style="visibility: hidden">
            <div *ngIf="editable">
              <span
                [ngClass]="isRect ? 'btnInvoiceType1' : 'btnInvoiceType'"
                (click)="drawrectangle()"
                style="cursor: pointer"
                >Tag</span
              >
            </div>
            <div *ngIf="!editable">
              <span class="btnInvoiceType" style="cursor: not-allowed"
                >Tag</span
              >
            </div>
          </div>
          <div id="parentDiv">
            <canvas #canvas id="canvas1" style="height: 87vh"></canvas>
          </div>
          <span class="zoom zoom_pos_img">
            <span class="zoom-in" (click)="zoomin()"
              ><i class="fa fa-plus" aria-hidden="true"></i></span
            ><br />
            <span class="zoom-out" (click)="zoomout()"
              ><i class="fa fa-minus" aria-hidden="true"></i
            ></span>
          </span>
        </div>
        <div *ngIf="!isImgBoolean" style="position: relative">
          <div
            *ngIf="isLoaded"
            style="text-align: center; position: fixed; z-index: 100; left: 70%"
          >
            <div>
              <button
                (click)="prevPage()"
                [disabled]="page === 1"
                class="preBtn"
              >
                Prev
              </button>
              <span class="totalPages">{{ page }} / {{ totalPages }}</span>
              <button
                (click)="nextPage()"
                [disabled]="page === totalPages"
                class="preBtn"
              >
                Next
              </button>
            </div>

            <div class="rotate_text">
              <button class="preBtn" (click)="rotate(-90)"><i class="fa fa-undo" aria-hidden="true"></i></button>
              <div class="f-12 ml-1">Rotate</div>
              <button class="preBtn mr-1" (click)="rotate(90)"><i class="fa fa-repeat" aria-hidden="true"></i></button>
            </div>

            <span class="zoom zoom_pos_pdf">
              <span class="zoom-in" (click)="zoomIn()"
                ><i class="fa fa-plus" aria-hidden="true"></i></span
              ><br />
              <span class="zoom-out" (click)="zoomOut()"
                ><i class="fa fa-minus" aria-hidden="true"></i
              ></span>
            </span>
          </div>

          <pdf-viewer
            #pdfviewer
            id="pdfContainer"
            [src]="showInvoice"
            style="width: 100%; overflow: auto"
            [show-all]="true"
            [zoom]="zoomdata"
            [original-size]="false"
            [fit-to-page]="true"
            [page]="page"
            [rotation]="rotation"
            [render-text]="true"
            [render-text-mode]="2"
            (after-load-complete)="afterLoadComplete($event)"
            (text-layer-rendered)="textLayerRendered($event)"
          >
          </pdf-viewer>
        </div>
      </section>
      <!-- <img [src]="imageUrl" style="width: 100%;height: 80vh;"><br/> -->
    </div>
  </div>
</div>
<!-- Add label-->
<!-- <p-dialog header="Add Label" [(visible)]="displayAddLabelDialog" [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '31vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div>
    <label class="label-head"> Label</label>
    <input type="text" class="form-control inputAddLabel mb-1" name="addLabel" ngModel #addLabel="ngModel"
      [(ngModel)]="customLabel" required>
      <div *ngIf="addLabel.errors &&(addLabel.touched || addLabel.dirty)" class ="alertDiv">
        <div [hidden]="!addLabel.errors.required">
          **Required field
        </div>
       </div>
    <label class="label-head">Value</label>
    <input type="text" class="form-control inputAddLabel mb-2" name="labelValue" ngModel #labelValue="ngModel" [(ngModel)]="customValue"
      >


  </div>
  <ng-template  pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button type="button" class="btn btnVender" (click)="displayAddLabelDialog = false">Cancel</button>
      <button type="button" class="btn btnVender"
        [disabled]="addLabel.invalid "
        [ngStyle]="{'cursor':(addLabel.invalid)? 'not-allowed':'pointer'}"
        (click)="addLabelData()">Add</button>
    </div>

  </ng-template>
</p-dialog> -->

<!-- Reject modal-->
<p-dialog
  [header]="comment_header"
  [(visible)]="displayrejectDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div
    *ngIf="
      isRejectCommentBoolean || isApproveCommentBoolean || preApproveBoolean
    "
  >
    <label class="label-head mt-2 mb-0">Comments</label> <br />
    <small *ngIf="preApproveBoolean" class="f-11 cmt_text"
      >Desc: Add the Names of the Signatory is mandatory</small
    >

    <textarea
      required
      name="comment"
      class="form-control"
      style="font-size: 13px"
      minlength="0"
      (keyup)="addComments($event.target.value)"
      cols="50"
      rows="2"
    ></textarea>
    <small class="noteCss" *ngIf="isRejectCommentBoolean"
      >NOTE: Add atleast 10 characters</small
    >
  </div>

  <div *ngIf="(isLCMSubmitBoolean && !preApproveBoolean) || deletepopBoolean">
    <div class="text-center">
      <div>
        <i
          class="pi pi-exclamation-triangle"
          style="font-size: 4rem; color: red"
        ></i>
      </div>
      <div class="deleteDivText">
        {{ BtnpopText }}
      </div>
    </div>
  </div>
  <div *ngIf="checkItemBoolean">
    <label class="f-12 mt-2 mb-0"
      >Please enter Item code number and Check is it available or not.</label
    >
    <input #ItemCode type="number" class="form-control f-12" />
    <button
      class="btn btnVender bg-btn-success mt-2"
      (click)="CheckItemStatus(ItemCode.value)"
    >
      Check & ADD
    </button>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center" *ngIf="!checkItemBoolean">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayrejectDialog = false"
      >
        Cancel
      </button>
      <button
        *ngIf="isRejectCommentBoolean"
        type="button"
        class="btn btnVender bg-btn-success"
        [disabled]="commentslabelBool"
        [ngStyle]="{ cursor: commentslabelBool ? 'not-allowed' : 'pointer' }"
        (click)="Reject()"
      >
        Send for Rejection
      </button>
      <button
        *ngIf="preApproveBoolean"
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="displayrejectDialog = false"
      >
        Ok
      </button>
      <button
        *ngIf="isApproveCommentBoolean"
        type="button"
        class="btn btnVender bg-btn-success"
        [disabled]="commentslabelBool"
        [ngStyle]="{ cursor: commentslabelBool ? 'not-allowed' : 'pointer' }"
        (click)="financeApprove()"
      >
        Approve
      </button>
      <button
        *ngIf="isLCMSubmitBoolean && !preApproveBoolean"
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="submitLCMLines()"
      >
        Confirm
      </button>
      <button
        type="button"
        *ngIf="deletepopBoolean"
        class="btn btnVender bg-btn-success"
        (click)="removeLine()"
      >
        Yes
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
